import React, { useState } from 'react';
import { Form, FormLayout, TextField, Modal, } from '@shopify/polaris';

const SupportForm = ({ isOpen, onClose, emailAddress, onSubmit }) => {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [email, setEmail] = useState(emailAddress);
  const [message, setMessage] = useState('');

  const handleFormSubmit = async () => {

    const contact = { name: storeDomain, email, message };
    console.log('Support Payload:', contact);

    const response = await fetch(`/api/contact_support?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ contact }),
    });

    if (response.ok) {
      console.log(response.status);
    } else {
      console.error('Failed to send contact us mail');
    }
    onSubmit({ email, message });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Contact Support"
      primaryAction={{
        content: 'Submit',
        onAction: handleFormSubmit,
      }}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
            <TextField
              label="Enter Your Email:"
              value={email}
              onChange={setEmail}
              placeholder="Your Email Address"
            />
            <TextField
              label="Enter Your Message:"
              value={message}
              onChange={setMessage}
              multiline={2}
              placeholder="Your Message"
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default SupportForm;
