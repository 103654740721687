import React, { useState } from "react";
import { Modal, TextField, FormLayout } from "@shopify/polaris";

export default function TestMessageModal({ isOpen, onClose, onSubmit }) {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customMessage, setCustomMessage] = useState(`Welcome to store ${storeDomain}, This is a test message`);

  const handleSend = () => {
    onSubmit({ phoneNumber, customMessage });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Send Test Message"
      primaryAction={{
        content: "Send",
        onAction: handleSend,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
            type="tel"
            placeholder="Enter test phone number"
            autoComplete="off"
          />
          <TextField
            label="Custom Message"
            value={customMessage}
            onChange={(value) => setCustomMessage(value)}
            multiline={3}
            placeholder="Enter your custom message"
            autoComplete="off"
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
