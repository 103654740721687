import React, { useState, useEffect, useCallback } from "react";
import { Page, Layout, FormLayout, Card, TextField, Toast, Frame, InlineStack, Button, Text, Collapsible } from "@shopify/polaris";
import { clickSend, msg91, plivo, vonage, twilio, custom } from "../assets";
import SupportForm from "./support";
import ConfirmationForm from "./confirmation";
import TestMessageModal from "./custom-message";

const textData = {
  twilio: {
    api_key: "Sender ID",
    api_secret: "Account SID",
    access_token: "Authentication Token",
    gateway_url: "Gateway URL",
  },
  vonage: {
    api_key: "Sender ID",
    api_secret: "API key",
    access_token: "API Secret",
    gateway_url: "Gateway URL",
  },
  plivo: {
    api_key: "Sender ID",
    api_secret: "Auth ID",
    access_token: "Authentication Token",
    gateway_url: "Gateway URL",
  },
  clickSend: {
    api_key: "Sender ID",
    api_secret: "API Key",
    access_token: "Username",
    gateway_url: "Gateway URL",
  },
  msg91: {
    api_key: "Sender ID",
    api_secret: "Account SID",
    access_token: "Access Token",
    gateway_url: "Gateway URL",
  },
  custom: {
    api_key: "API Key",
    api_secret: "API Secret",
    access_token: "Access Token",
    gateway_url: "Gateway URL",
  },
};


export default function Configuration() {
  
  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false);
  const [selected, setSelected] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(true);
  const [formData, setFormData] = useState({ api_key: "", api_secret: "", access_token: "", active: "false", });
  const [gatewayData, setGatewayData] = useState({
    twilio: {
      api_key: "",
      api_secret: "",
      access_token: "",
      active: "false",
      gateway_url: "",
    },
    vonage: {
      api_key: "",
      api_secret: "",
      access_token: "",
      active: "false",
      gateway_url: "",
    },
    plivo: {
      api_key: "",
      api_secret: "",
      access_token: "",
      active: "false",
      gateway_url: "",
    },
    clickSend: {
      api_key: "",
      api_secret: "",
      access_token: "",
      active: "false",
      gateway_url: "",
    },
    msg91: {
      api_key: "",
      api_secret: "",
      access_token: "",
      active: "false",
      gateway_url: "",
    },
    custom: {
      api_key: "",
      api_secret: "",
      access_token: "",
      active: "false",
      gateway_url: "",
    },
  });  

  const gateways = [
    { id: "twilio", name: "Twilio", icon: twilio, color: "rgb(207 39 45)" },
    { id: "vonage", name: "Vonage", icon: vonage, color: "rgb(19 20 21)" },
    { id: "plivo", name: "Plivo", icon: plivo, color: "rgb(2 163 70)" },
    { id: "clickSend", name: "ClickSend", icon: clickSend, color: "rgb(0 165 255)" },
    { id: "msg91", name: "MSG91", icon: msg91, color: "rgb(36 123 190)" },
    { id: "custom", name: "Custom", icon: custom, color: "rgb(19 20 21)" },
  ];

  const handleTestMessageSubmit = async (data) => {
    
    console.log("Sending test message to:", data.phoneNumber);
    console.log("With custom message:", data.customMessage);
  
    try {
      const gatewaySettings = gatewayData[selected];
  
      const requestBody = {
        phoneNumber: data.phoneNumber,
        customMessage: data.customMessage,
        gateway: selected,
        apiKey: gatewaySettings.api_key,
        apiSecret: gatewaySettings.api_secret,
        accessToken: gatewaySettings.access_token,
        gatewayUrl: gatewaySettings.gateway_url,
      };
  
      const response = await fetch(`/api/send_sms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-shopify-shop-domain": storeDomain
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        console.log("Test message sent successfully.");
        setIsToastActive(true);
      } else {
        console.error("Failed to send test message.");
      }
    } catch (error) {
      console.error("Error sending test message:", error);
    }
  };  

  const toggleTestModal = () => {
    setIsTestModalOpen(!isTestModalOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-shopify-shop-domain": storeDomain
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          if (data.gateway_settings) {
            const storeData = data.gateway_settings;
            console.log(storeData, data.storeDetails.email);
            setEmailAddress(data.storeDetails.email);
            setGatewayData((prevData) => {
              const updatedData = {
                ...prevData,
                ...storeData,
              };
              for (const key in updatedData) {
                if (updatedData[key].active === "true") {
                  setFormData(updatedData[key]);
                  setSelected(key);
                }
              }
              return updatedData;
            });
          }
        } else {
          console.log("No Data Found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleSubmit = async () => {
    
    console.log("Submitting form data:", formData);
  
    let updatedGatewayData = { ...gatewayData };
    for (const key in updatedGatewayData) {
      if (key !== selected) {
        updatedGatewayData[key].active = "false";
      }
    }
    updatedGatewayData[selected] = { ...formData, active: "true" };
    setGatewayData(updatedGatewayData);
  
    try {
      const response = await fetch(`/api/update_config`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-shopify-shop-domain": storeDomain
        },
        body: JSON.stringify({ gateway_settings: updatedGatewayData }),
      });
  
      if (response.ok) {
        console.log("Settings saved successfully.");
        setIsToastActive(true);
      } else {
        console.error("Failed to save settings.");
      }
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };  

  const handleIconClick = (id) => {
    setSelected(selected !== id ? id : null);
    setFormData(gatewayData[id]);
  };

  const handleActivation = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      active: value,
    }));
    setGatewayData((prevData) => ({
      ...prevData,
      [selected]: {
        ...prevData[selected],
        active: value,
      },
    }));
    setIsConfirmationModalOpen(true);
  };

  const handleChange = (selected, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setGatewayData((prevData) => ({
      ...prevData,
      [selected]: {
        ...prevData[selected],
        [field]: value,
      },
    }));
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
  };

  const toggleSupportModal = () => {
    setIsSupportModalOpen(!isSupportModalOpen);
  };

  const toggleInstructions = useCallback(() => setIsInstructionsOpen((isOpen) => !isOpen), []);

  const confirmationFormMarkup = (
    <ConfirmationForm
      isOpen={isConfirmationModalOpen}
      onClose={toggleConfirmationModal}
      onSubmit={handleSubmit}
      selected={selected}
      onReset={handleActivation}
      data={formData}
    />
  );

  const supportFormMarkup = (
    <SupportForm
      isOpen={isSupportModalOpen}
      onClose={toggleSupportModal}
      emailAddress={emailAddress}
      onSubmit={(formData) => {
        console.log("Support Form Data:", formData);
      }}
    />
  );

  const testMessageModal = (
    <TestMessageModal
      isOpen={isTestModalOpen}
      onClose={toggleTestModal}
      onSubmit={handleTestMessageSubmit}
    />
  );

  const dismissToast = () => {
    setIsToastActive(false);
  };

  const toastMarkup = isToastActive ? (
    <Toast content="Settings saved successfully" onDismiss={dismissToast} />
  ) : null;

  const getButtonStyles = (isSelected, color) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "inherit",
    width: "50px",
    height: "50px",
    padding: "5px",
    margin: "5px",
    borderRadius: "50%",
    border: isSelected ? `4px solid ${color}` : "none",
    cursor: "pointer",
    transform: isSelected ? "scale(1.4)" : "scale(1)",
    transition: "transform 0.2s ease, border 0.2s ease",
    overflow: "hidden",
    objectFit: "cover",
  });

  const getImageStyles = () => ({
    width: "100%",
    height: "100%",
    aspectRatio: "3/2",
    objectFit: "cover",
    borderRadius: "10px",
  });

  const notifyBadge = {
    position: "absolute",
    background: "rgba(9 164 56)",
    height: "1rem",
    top: "0rem",
    right: "-0.3rem",
    width: "1rem",
    textAlign: "center",
    fontSize: "1rem",
    borderRadius: "50%",
    zIndex: "1",
  };

  return (
    <div style={{ height: "250px" }}>
      <Frame>
        <Page 
          title="Configure Gateway"
          primaryAction={{
            content: 'Need Help?',
            onAction: toggleSupportModal,
          }}>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <div style={{ marginBottom: "20px" }}>
                  <Text variant="headingMd" as="h6">
                    Select Gateway You Want To Use
                  </Text>
                </div>
                <InlineStack
                  distribution="center"
                  wrap={false}
                  align="space-evenly"
                >
                  {gateways.map(({ icon, id, color, name }) => (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      key={id}
                    >
                      {gatewayData[id].active === "true" ? (
                        <div style={notifyBadge}></div>
                      ) : (
                        <></>
                      )}
                      <button
                        style={getButtonStyles(selected === id, color)}
                        onClick={() => handleIconClick(id)}
                      >
                        <img src={icon} alt={id} style={getImageStyles()} />
                      </button>
                      <Text variant="headingSm" as="h6">
                        {name}
                      </Text>
                    </div>
                  ))}
                </InlineStack>
                <br /><br />
                {selected && (
                  <FormLayout>
                    {Object.keys(gatewayData[selected])
                      .filter(field => field !== "active")
                      .map(field => (
                        <TextField
                          key={field}
                          label={textData[selected][field]}
                          value={gatewayData[selected][field]}
                          placeholder={`Enter Your ` + textData[selected][field]}
                          onChange={(value) => handleChange(selected, field, value)}
                          autoComplete="off"
                          disabled={field === "gateway_url" && selected !== 'custom'}
                        />
                      ))}
                    <div style={{ textAlign: "right" }}>
                      {gatewayData[selected]?.active === "false" ? (
                        <div style={{ display: 'flex', gap: '10px', marginTop: '20px', float: 'right' }}>
                          <Button
                            variant="primary"
                            size="large"
                            onClick={() => handleActivation("true")}
                          >
                            Save And Activate
                          </Button>
                          <Button
                            onClick={toggleInstructions}
                            ariaExpanded={isInstructionsOpen}
                            ariaControls="basic-collapsible"
                          >
                            {isInstructionsOpen ? "Hide Instructions" : "Show Instructions"}
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', gap: '10px', marginTop: '20px', float: 'right' }}>
                          <Button variant="primary" size="large" onClick={handleSubmit}>Save</Button>
                          <Button onClick={toggleTestModal}>Send Message</Button>
                          <Button variant="primary" tone="critical" onClick={() => handleActivation("false")}>Deactivate</Button>
                          <Button
                            onClick={toggleInstructions}
                            ariaExpanded={isInstructionsOpen}
                            ariaControls="basic-collapsible"
                          >
                            {isInstructionsOpen ? "Hide Instructions" : "Show Instructions"}
                          </Button>
                        </div>
                      )}
                    </div>
                    <Collapsible
                        open={isInstructionsOpen}
                        id="basic-collapsible"
                        transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
                      >
                        <Text as="p" variant="bodyMd">
                          <strong>Instructions:</strong><br />
                          1. Select your preferred gateway from the options above.<br />
                          2. Fill in the required fields like Sender ID, Account SID, and Access Token.<br />
                          3. Once filled, save the configuration by clicking on 'Save and Activate'.<br />
                          4. You can also deactivate the gateway if needed or send a test message.<br />
                          5. Use the 'Need Help?' button for support if you encounter any issues.
                        </Text>
                      </Collapsible>
                  </FormLayout>
                )}
              </Card>
            </Layout.Section>
          </Layout>
          {toastMarkup}
          {confirmationFormMarkup}
          {supportFormMarkup}
          {testMessageModal}
        </Page>
      </Frame>
    </div>
  );
}