import React from 'react';
import { Text, Modal, } from '@shopify/polaris';

const ConfirmationForm = ({ isOpen, onClose, onSubmit, selected, onReset, data }) => {

  const handleFormSubmit = async () => {
    onSubmit();
    onClose();
  };

  const handlemodalClose = async () => {
    onReset(data?.active === "true" ? "false" : "true");
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      primaryAction={{
        content: data?.active === "true" ? "Activate" : "Deactivate",
        onAction: handleFormSubmit,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handlemodalClose,
        },
      ]}
    >
      <Modal.Section>
            <Text variant="bodyLg" as="p">
                Are you sure, you want to activate {selected} gateway ?
            </Text>
          </Modal.Section>
    </Modal>
  );
};

export default ConfirmationForm;
