import React, { useState, useEffect } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame, Page, Tabs } from '@shopify/polaris';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import Configuration from './views/configuration.jsx';
import CustomerNotification from './views/customer-notification.jsx';
import AllUserNotification from './views/alluser-notification.jsx';
import Pricing from './views/pricing.jsx';

const App = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [config, setConfig] = useState(null);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config');
        if (!response.ok) {
          throw new Error('Failed to fetch App Bridge config');
        }
        const data = await response.json();
        const params = new URLSearchParams(window.location.search);
        const host = params.get('host');
        
        setConfig({
          apiKey: data.apiKey,
          shopOrigin: data.shopOrigin,
          host: host,
          forceRedirect: true,
          webVitals: false,
        });
      } catch (error) {
        console.error('Error fetching App Bridge config:', error);
      }
    };
    fetchConfig();
  }, []);

  // if (!config) {
  //   return <div>Loading...</div>;
  // }

  return (
    <AppProvider i18n={enTranslations}>
      {/* <AppBridgeProvider> */}
        <Frame sandbox="allow-scripts allow-same-origin allow-popups allow-forms">
          <Page>
            <Tabs
              tabs={[
                {
                  id: "Configuration",
                  content: "Gateway Settings",
                },
                {
                  id: "customernotification",
                  content: "Customers Notification",
                },
                {
                  id: "allusernotification",
                  content: "Bulk Notification",
                },
                {
                  id: "Pricing",
                  content: "Pricing Plan",
                },
              ]}
              selected={selectedTab}
              onSelect={handleTabChange}
            >
              {selectedTab === 0 && <Configuration />}
              {selectedTab === 1 && <CustomerNotification />}
              {selectedTab === 2 && <AllUserNotification />}
              {selectedTab === 3 && <Pricing />}
            </Tabs>
          </Page>
        </Frame>
      {/* </AppBridgeProvider> */}
    </AppProvider>
  );
}

export default App;
