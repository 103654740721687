import React, { useState, useEffect } from "react";
import { Page, Layout, FormLayout, Card, TextField, Form, DataTable, Toast, Frame, Checkbox, } from "@shopify/polaris";

export default function SendMessageToAllUsers() {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [userData, setUserData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [messageBody, setMessageBody] = useState(
    "Hello [CustomerName], Exclusive Offer just for you! Get 50% off on your next purchase at https://[StoreName]/. Use code PromoCode at checkout. Hurry, offer ends soon!"
  );
  const [isToastActive, setIsToastActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/customer_data?shop=${storeDomain}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          const userDataArray = data.map((user) => ({
            id: user.id,
            email: user.email,
            first_name: user.first_name + " " + user.last_name,
            tags: user.tags,
            phone: user.phone,
          }));
          setUserData(userDataArray);
          setFilteredUserData(userDataArray);
        } else {
          console.log("No Data Found");
        }
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    const filteredData = userData.filter(
      (user) =>
        user.email.toLowerCase().includes(value.toLowerCase()) ||
        user.first_name.toLowerCase().includes(value.toLowerCase()) ||
        user.tags.toLowerCase().includes(value.toLowerCase()) ||
        user.phone.includes(value)
    );
    setFilteredUserData(filteredData);
    setSelectAll(false);
    setSelectedUsers([]);
  };

  const handleCheckboxChange = (id) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      const allUserIds = filteredUserData.map((user) => user.id);
      setSelectedUsers(allUserIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSendMessage = async () => {
    const selectedUserPhones = filteredUserData
      .filter((user) => selectedUsers.includes(user.id))
      .map((user) => user.phone);

    const selectedUserNames = filteredUserData
      .filter((user) => selectedUsers.includes(user.id))
      .map((user) => user.first_name);

    const data = {
      all_user_notif: messageBody,
      selected_user_phones: selectedUserPhones,
      selected_user_names: selectedUserNames,
    };

    console.log("Sending message to:", data);
    setIsToastActive(true);
    const response = await fetch(`/api/send_message_to_all_users?shop=${storeDomain}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-shopify-shop-domain": storeDomain,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      console.log("Message sent successfully.");
    } else {
      console.log("Failed to send the message");
    }
  };

  const dismissToast = () => {
    setIsToastActive(false);
  };

  const toastMarkup = isToastActive ? (
    <Toast
      content="Message sent successfully to selected users"
      onDismiss={dismissToast}
    />
  ) : null;

  const rows = filteredUserData.map((user) => [
    <Checkbox
      checked={selectedUsers.includes(user.id)}
      onChange={() => handleCheckboxChange(user.id)}
    />,
    user.id,
    user.email,
    user.first_name,
    user.tags,
    user.phone,
  ]);

  return (
    <div style={{ height: "250px" }}>
      <Frame>
        <Page
          title="Send Message To All Users"
          subtitle="This feature allows you to send an instant message to users present in the store. You can also select few of them."
          primaryAction={{
            content: "Send SMS",
            onAction: handleSendMessage,
          }}
        >
          <Layout>
            <Layout.Section>
              <Card>
                <Form>
                  <FormLayout>
                    <TextField
                      label="Message Body:"
                      name="messageBody"
                      value={messageBody}
                      onChange={setMessageBody}
                      multiline={3}
                      placeholder="Enter Message..."
                    />
                  </FormLayout>
                </Form>
              </Card>
              <br />
              <Card>
                <TextField
                  label="Search Users"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search by Email, Name, Tags, or Phone"
                />
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                  ]}
                  headings={[
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />,
                    "#ID",
                    "Email Address",
                    "First Name",
                    "Tags",
                    "Phone Number",
                  ]}
                  rows={rows}
                />
              </Card>
            </Layout.Section>
          </Layout>
          {toastMarkup}
        </Page>
      </Frame>
    </div>
  );
}
