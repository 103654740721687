import React, { useState, useEffect } from "react";
import { Page, Layout, FormLayout, Card, TextField, Form, Checkbox, Toast, Frame } from "@shopify/polaris";

export default function CustomerNotificationSettings() {

    const storeDomain = new URL(window.location).searchParams.get("shop");
    const [customerRegistration, setCustomerRegistration] = useState("Welcome [CustomerName]! Thanks for registering with [StoreName]. We're excited to have you on board");
    const [productPriceDrop, setProductPriceDrop] = useState("Great news, [CustomerName]! The price of [ProductName] has dropped to [NewPrice]! Hurry, grab it now: [ProductLink]");
    const [productInStock, setProductInStock] = useState("Hi [CustomerName], [ProductName] is back in stock! Don't miss out again. Order now: [ProductLink]");
    const [cartsUpdate, setCartsUpdate] = useState("Hey [CustomerName], your cart at [StoreName] is waiting for you! Complete your purchase now: [CartLink]");
    const [orderCreate, setOrderCreate] = useState("Thanks for your order, [CustomerName]! Your order #[OrderNumber] has been received. We'll notify you when it's shipped. View details: [OrderLink]");
    const [orderPaid, setOrderPaid] = useState("Payment received! Your order #[OrderNumber] is confirmed. We'll start preparing it for shipment. Track your order: [OrderLink]");
    const [orderCancellation, setOrderCancellation] = useState("Hi [CustomerName], your order #[OrderNumber] has been successfully canceled. If you have any questions, contact us at [CustomerEmail]");
    const [orderRefund, setOrderRefund] = useState("Hi [CustomerName], a refund for your order #[OrderNumber] has been processed. You should see the amount in your account soon. Details: [RefundAmount]");
    const [ordersItemsPickup, setOrdersItemsPickup] = useState("Good news, [CustomerName]! Your order #[OrderNumber] is ready for pickup. Track your order: [OrderLink]");
    const [ordersItemsLocalDelivery, setOrdersItemsLocalDelivery] = useState("Hi [CustomerName], your order #[OrderNumber] is ready for local delivery. Track your order: [OrderLink]");

    const [isCustomerRegistrationChecked, setIsCustomerRegistrationChecked] = useState(false);
    const [isProductPriceDropChecked, setIsProductPriceDropChecked] = useState(false);
    const [isProductInStockChecked, setIsProductInStockChecked] = useState(false);
    const [isCartsUpdateChecked, setIsCartsUpdateChecked] = useState(false);
    const [isOrderCreateChecked, setIsOrderCreateChecked] = useState(false);
    const [isOrderPaidChecked, setIsOrderPaidChecked] = useState(false);
    const [isOrderCancellationChecked, setIsOrderCancellationChecked] = useState(false);
    const [isOrderRefundChecked, setIsOrderRefundChecked] = useState(false);
    const [isOrdersItemsPickupChecked, setIsOrdersItemsPickupChecked] = useState(false);
    const [isOrdersItemsLocalDeliveryChecked, setIsOrdersItemsLocalDeliveryChecked] = useState(false);
    const [isToastActive, setIsToastActive] = useState(false);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await fetch(`/api/get_data`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-shopify-shop-domain": storeDomain,
                    },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    if (data.customer_settings) {
                        const customerSettings = data.customer_settings;
    
                        setCustomerRegistration(() => customerSettings.customerRegistration || customerRegistration);
                        setProductPriceDrop(() => customerSettings.productPriceDrop || productPriceDrop);
                        setProductInStock(() => customerSettings.productInStock || productInStock);
                        setCartsUpdate(() => customerSettings.cartsUpdate || cartsUpdate);
                        setOrderCreate(() => customerSettings.orderCreate || orderCreate);
                        setOrderPaid(() => customerSettings.orderPaid || orderPaid);
                        setOrderCancellation(() => customerSettings.orderCancellation || orderCancellation);
                        setOrderRefund(() => customerSettings.orderRefund || orderRefund);
                        setOrdersItemsPickup(() => customerSettings.ordersItemsPickup || ordersItemsPickup);
                        setOrdersItemsLocalDelivery(() => customerSettings.ordersItemsLocalDelivery || ordersItemsLocalDelivery);
    
                        setIsCustomerRegistrationChecked(() => customerSettings.isCustomerRegistrationChecked ?? false);
                        setIsProductPriceDropChecked(() => customerSettings.isProductPriceDropChecked ?? false);
                        setIsProductInStockChecked(() => customerSettings.isProductInStockChecked ?? false);
                        setIsCartsUpdateChecked(() => customerSettings.isCartsUpdateChecked ?? false);
                        setIsOrderCreateChecked(() => customerSettings.isOrderCreateChecked ?? false);
                        setIsOrderPaidChecked(() => customerSettings.isOrderPaidChecked ?? false);
                        setIsOrderCancellationChecked(() => customerSettings.isOrderCancellationChecked ?? false);
                        setIsOrderRefundChecked(() => customerSettings.isOrderRefundChecked ?? false);
                        setIsOrdersItemsPickupChecked(() => customerSettings.isOrdersItemsPickupChecked ?? false);
                        setIsOrdersItemsLocalDeliveryChecked(() => customerSettings.isOrdersItemsLocalDeliveryChecked ?? false);
                    }
                } else {
                    console.log('No Data Found');
                }
            } catch (error) {
                console.log('Error fetching settings:', error);
            }
        };
    
        fetchSettings();
    }, [storeDomain]); // Only run on mount with storeDomain dependency

    const handleSaveSettings = async () => {
        setIsToastActive(true);

        const customer_settings = {
            customerRegistration,
            productPriceDrop,
            productInStock,
            cartsUpdate,
            orderCreate,
            orderPaid,
            orderCancellation,
            orderRefund,
            ordersItemsPickup,
            ordersItemsLocalDelivery,

            isCustomerRegistrationChecked,
            isProductPriceDropChecked,
            isProductInStockChecked,
            isCartsUpdateChecked,
            isOrderCreateChecked,
            isOrderPaidChecked,
            isOrderCancellationChecked,
            isOrderRefundChecked,
            isOrdersItemsPickupChecked,
            isOrdersItemsLocalDeliveryChecked,
        };
        
        try {
            const response = await fetch(`/api/update_config`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "x-shopify-shop-domain": storeDomain
                },
                body: JSON.stringify({ customer_settings }),
            });

            if (response.ok) {
                console.log("Customers settings updated successfully.");
            } else {
                console.log("Failed to update settings.");
            }
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    const dismissToast = () => {
        setIsToastActive(false);
    };

    const toastMarkup = isToastActive ? (
        <Toast content="Customers settings saved successfully" onDismiss={dismissToast} />
    ) : null;

    const sections = [
        {
            title: "Customers Account Creation",
            description: "This will allow you to send a notification to your users on new account creation",
            label: "Enable Customer Registration Notification",
            checked: isCustomerRegistrationChecked,
            setChecked: setIsCustomerRegistrationChecked,
            value: customerRegistration,
            setValue: setCustomerRegistration,
            name: "customerRegistration"
        },
        {
            title: "Notification on Product Price Drop",
            description: "This will allow you to send a notification to your users on product price drop",
            label: "Enable Product Price Drop notification",
            checked: isProductPriceDropChecked,
            setChecked: setIsProductPriceDropChecked,
            value: productPriceDrop,
            setValue: setProductPriceDrop,
            name: "productPriceDrop"
        },
        {
            title: "Notification on Product in Stock",
            description: "This will allow you to send a notification to your users on product restock",
            label: "Enable Product in Stock Notification" ,
            checked: isProductInStockChecked,
            setChecked: setIsProductInStockChecked,
            value: productInStock,
            setValue: setProductInStock,
            name: "productInStock"
        },
        {
            title: "Notification on Carts Update",
            description: "This will allow you to send a notification to your users on cart update",
            label: "Enable Carts Update notification",
            checked: isCartsUpdateChecked,
            setChecked: setIsCartsUpdateChecked,
            value: cartsUpdate,
            setValue: setCartsUpdate,
            name: "cartsUpdate"
        },
        {
            title: "Notification on Order Creation",
            description: "This will allow you to send a notification to your users on new order create",
            label: "Enable Order Create Notification" ,
            checked: isOrderCreateChecked,
            setChecked: setIsOrderCreateChecked,
            value: orderCreate,
            setValue: setOrderCreate,
            name: "orderCreate"
        },
        {
            title: "Notification on Order Paid",
            description: "This will allow you to send a notification to your users for order paid",
            label: "Enable Order Paid Notification" ,
            checked: isOrderPaidChecked,
            setChecked: setIsOrderPaidChecked,
            value: orderPaid,
            setValue: setOrderPaid,
            name: "orderPaid"
        },
        {
            title: "Notification on Order Cancellation",
            description: "This will allow you to send a notification to your users on order cancelled",
            label: "Enable Order Cancellation Notification" ,
            checked: isOrderCancellationChecked,
            setChecked: setIsOrderCancellationChecked,
            value: orderCancellation,
            setValue: setOrderCancellation,
            name: "orderCancellation"
        },
        {
            title: "Notification on Order Refund",
            description: "This will allow you to send a notification to your users for order refund",
            label: "Enable Order Refund Notification" ,
            checked: isOrderRefundChecked,
            setChecked: setIsOrderRefundChecked,
            value: orderRefund,
            setValue: setOrderRefund,
            name: "orderRefund"
        },
        {
            title: "Notification on Orders Items Pickup",
            description: "This will allow you to send a notification to your users on orders items pickup",
            label: "Enable Orders Items Pickup Notification" ,
            checked: isOrdersItemsPickupChecked,
            setChecked: setIsOrdersItemsPickupChecked,
            value: ordersItemsPickup,
            setValue: setOrdersItemsPickup,
            name: "ordersItemsPickup"
        },
        {
            title: "Notification on Orders Items Local Delivery",
            description: "This will allow you to send a notification to your users on orders local delivery",
            label: "Enable Orders Items Local Delivery Notification" ,
            checked: isOrdersItemsLocalDeliveryChecked,
            setChecked: setIsOrdersItemsLocalDeliveryChecked,
            value: ordersItemsLocalDelivery,
            setValue: setOrdersItemsLocalDelivery,
            name: "ordersItemsLocalDelivery"
        },
    ];

    return (
        <div style={{ height: '250px' }}>
            <Frame>
            <Page
                title="Customer SMS Notifications"
                subtitle="Customers will receive SMS notifications for the following enabled status"
                primaryAction={{ content: 'Save', onAction: handleSaveSettings }}
            >
                <Layout>
                    {sections.map((section, index) => (
                        <Layout.AnnotatedSection
                            key={index}
                            title={section.title}
                            description={section.description}
                        >
                            <Card sectioned>
                                <Form>
                                    <FormLayout>
                                        <Checkbox
                                            label={`Enable ${section.title} Notification`}
                                            checked={section.checked}
                                            onChange={() => section.setChecked(!section.checked)}
                                        />
                                        <TextField
                                            label="Message Template"
                                            name={section.name}
                                            value={section.value}
                                            onChange={(value) => section.setValue(value)}
                                            multiline={2}
                                            maxLength={160}
                                            autoComplete="off"
                                            showCharacterCount
                                        />
                                    </FormLayout>
                                </Form>
                            </Card>
                        </Layout.AnnotatedSection>
                    ))}
                </Layout>
                {toastMarkup}
            </Page>
            </Frame>
        </div>
    );
}
